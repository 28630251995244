// ナビゲーションアニメーション
$(".navbar-btn").click(function () {//ボタンがクリックされたら
    $(this).toggleClass('active');//ボタン自身に activeクラスを付与し
    $("#Gnav").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
    $(".gnav__circle").toggleClass('circleactive');//丸背景にcircleactiveクラスを付与
});

$("#Gnav a").click(function () {//ナビゲーションのリンクがクリックされたら
    $(".navbar-btn").removeClass('active');//ボタンの activeクラスを除去し
    $("#Gnav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスを除去
    $(".gnav__circle").removeClass('circleactive');//丸背景のcircleactiveクラスを除去
});
