window.WebFontConfig = {
  google: { families: ['Noto+Serif+JP:400,500', 'Yuji Boku', 'Kaisei Tokumin', 'Material Icons Round', 'Noto+Sans+JP:100,400,700,900'] },
  active: function() {
    sessionStorage.fonts = true;
  }
};

(function() {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();